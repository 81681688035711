import Header from 'src/components/HeaderPages/HeaderPages.vue'
import ConteudoModel from 'src/model/usuario/ConteudoModel'
import Carregando from 'src/components/CarregandoGeral/Carregando.vue'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'

export default {
  name: 'regulamento',
  components: { Header, Carregando },
  mixin: [NotificacaoMixin],
  meta () {
    return {
      title: 'Termos e Condições - NetCarros',
      description: { name: 'description', content: 'Somos transparentes e separamos uma página para você entender e ver como usasmos os seus dados e infomações pessoais. NetCarros, transparencia e segurança!' },
      property: { 'name': 'og:image', content: 'https://www.netcarros.com.br/seo/netcarros-home.jpg' },
      link: { 'rel': 'canonical', 'href': 'https://www.netcarros.com.br' + this.$route.fullPath },
      meta: [
        { 'property': 'og:title', 'content': 'Termos e Condições - NetCarros' },
        { 'name': 'description', 'content': 'Somos transparentes e separamos uma página para você entender e ver como usasmos os seus dados e infomações pessoais. NetCarros, transparencia e segurança!' },
        { 'property': 'revisit-after', 'content': '5 days' },
        { 'property': 'og:image', 'content': 'https://www.netcarros.com.br/seo/netcarros-home.jpg' },
        { 'property': 'og:image:secure_url', 'content': 'https://www.netcarros.com.br/seo/netcarros-home.jpg' },
        { 'property': 'og:url', 'content': 'https://www.netcarros.com.br' + this.$route.fullPath },
        { 'property': 'og:site_name', 'content': 'NetCarros' },
        { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
        { 'property': 'og:locale', 'content': 'pt_BR' }
      ]
    }
  },
  data () {
    return {
      page: 'Regulamento',
      modelPadrao: new ConteudoModel(),
      carregando: true,
      conteudo: null,
      tipo: ''
    }
  },
  watch: {
    '$route.params.url': {
      handler (agora, antes) {
        if (agora !== antes) {
          this.verificarRota()
        }
      }
    }
  },
  methods: {
    buscarConteudo (chave) {
      this.modelPadrao.getListagem({ params: { search: `chave_interna:${chave}` } }).then((response) => {
        this.conteudo = response.dados[0].conteudo
        this.carregando = false
      }).catch((error) => {
        this.notificacao('erro', error.msg)
        this.carregando = false
      })
    },
    verificarRota () {
      this.carregando = true
      switch (this.$route.params.url) {
        case 'politica-de-garantia':
          this.tipo = 'Políticas de garantia'
          this.buscarConteudo('politica-de-garantia')
          break
        case 'politica-de-privacidade':
          this.tipo = 'Política de privacidade'
          this.buscarConteudo('regulamento-politica-privacidade')
          break
        case 'politica-de-promocoes':
          this.tipo = 'Política de promoções'
          this.buscarConteudo('regulamento-politica-promocoes')
          break
        case 'termos-de-uso':
          this.tipo = 'Termos de uso'
          this.buscarConteudo('regulamento-termos-de-uso')
          break
        case 'politica-de-cookies':
          this.tipo = 'Política de cookies'
          this.buscarConteudo('regulamento-politica-de-cookies')
          break
        default:
          this.carregando = false
          break
      }
    },
    verificarTitulo () {
      switch (this.$route.params.url) {
        case 'politica-de-privacidade':
          return 'Política de privacidade'
        case 'politica-de-promocoes':
          return 'Política de promoções'
        case 'termos-de-uso':
          return 'Termos de uso'
        case 'politica-de-cookies':
          return 'Política de cookies'
      }
    }
  },
  mounted () {
    this.verificarRota()
  }
}
